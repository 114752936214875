<template>
    <div v-loading="initLoading">
        <div class="mainList" :class="{ 'showAdvancedSearchDrawer':advancedSearchShow }">

            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus"  @click="add">新增</el-button>
                        <el-button type="danger" icon="el-icon-delete"  size="small" @click="del()">批量删除</el-button>
                    </div>

                    <div class="mainList_content_operation-c" v-if="searchData.length">
                        <div class="item" v-for="(n,index) in searchData" v-if="index<2">
                            <div class="label">{{ n.fieldLabel?n.fieldLabel:n.fieldName }}</div>
                            <el-input placeholder="请输入" size="small" clearable v-model="n.fieldVal" />
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>

                    <div class="mainList_content_operation-r">
                        <div v-if="searchData.length>2" class="item icon iconfont icongaojisousuo" @click="advancedSearchShow = true"></div>
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>

                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" :stripe="elTable.stripe" :border="elTable.border" :data="tableData" tooltip-effect="dark" height="string" @sort-change="sortChange" v-loading="loading" header-cell-class-name="meterHeader">

                        <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>

                        <el-table-column v-for="n in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>


                        <el-table-column label="操作" fixed="right"  align="center" width="320" >
                            <template slot-scope="scope">
                                <el-button type="success" icon="el-icon-edit" size="mini" @click="edit(scope.row)">编辑</el-button>
                                <el-button type="danger" icon="el-icon-delete" size="mini" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @current-change="pageChangeHandler"
                        @size-change="handleSizeChange"
                        :current-page="parameter.pageNumber"
                        :page-size="parameter.pageSize"
                        prev-text="上一页"
                        next-text="下一页"
                        :total="parameter.total">
                    </el-pagination>
                </div>
            </div>
            <el-drawer
                class="advancedSearchShowDrawer"
                direction="rtl"
                :append-to-body="false"
                :modal="false"
                :visible.sync="advancedSearchShow"
                title="高级筛选"
            >
                <div class="con">
                    <div class="sub">
                        <div class="item" v-for="(n,index) in searchData">
                            <div class="label">{{ n.fieldLabel?n.fieldLabel:n.fieldName }}</div>
                            <el-input placeholder="请输入" size="medium" clearable v-model="n.fieldVal" />
                        </div>
                    </div>
                    <div class="foo">
                        <el-button size="small" plain @click="reset">取消</el-button>
                        <el-button size="small" type="primary" @click="pageChangeHandler(1)">确定</el-button>
                    </div>
                </div>


            </el-drawer>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options" :formConfig="formConfig"></Edit>

    </div>
</template>
<script>

import mixinsList from '@/mixins/list'
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            formConfig:{fields:[]},
            initLoading:true,
            api:{
                findPage:this.$api['survey/geologyReceptionData'].findByPage,
            },
            tableColumnData:[]
        };
    },
    created() {
        this.init();
    },
    methods: {},
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
